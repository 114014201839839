module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-scroll-reveal/gatsby-browser.js'),
      options: {"plugins":[],"threshold":0.2,"rootMargin":"0px 0px 0px 0px"},
    },{
      plugin: require('../plugins/gatsby-plugin-atollon/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"&Gray","short_name":"&Gray","start_url":"/","background_color":"#2D2A27","theme_color":"#2D2A27","display":"minimal-ui","icon":"/opt/build/repo/src/assets/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"3410c0bcfabf7823004c73d5e5fc7842"},
    },{
      plugin: require('../plugins/gatsby-plugin-atollon/node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://andgray.com.au","stripQueryString":true},
    },{
      plugin: require('../plugins/gatsby-plugin-atollon/gatsby-browser.js'),
      options: {"plugins":[],"datoCmsFullAccessApiKey":"ca839f58e650037d8b3fbd312a6b6b","datoCmsUrl":"https://and-gray.admin.datocms.com","draftsUrl":"https://andgray-drafts.netlify.app","productionUrl":"https://andgray.com.au","site":{"name":"&Gray","defaultMetaDescription":"It’s never just a choice between black or white.","facebookPageUrl":"https://facebook.com/andgray","twitterAccount":"@andgray","primaryColor":"#2D2A27","lightColor":"#FFFFFF","accentColor":"#2D2A27","darkColor":"#2D2A27","icon":"src/assets/images/favicon.png"},"email":{"host":"mail.audallabs.com","port":465,"username":"noreply@audallabs.com","password":"AHhrNmPI&HJE","sender":"noreply@audallabs.com"},"connectors":[{"id":"vault","type":"real-estate","data":{"provider":"vault-re","apiKey":"E2qrY9YS3I4s57wPNl01O6PhMRF6W9FH6tuVz5RX","bearerToken":"Dyqltmdoniknavputfrlomxzxmtqmikewiyxeysb"}}],"locales":[],"admins":[],"models":[{"name":"Pages","apiKey":"page","apiKeyMultiple":"pages","type":"page","hasBlocks":true,"isDefaultPageType":true},{"name":"Header","apiKey":"header_menu_item","apiKeyMultiple":"header_menu_items","type":"menu"},{"name":"Footer","apiKey":"footer_menu_item","apiKeyMultiple":"footer_menu_items","type":"menu"},{"name":"Site Options","apiKey":"site_option_page","apiKeyMultiple":"site_option_pages","type":"site-options"},{"name":"Properties","apiKey":"property","apiKeyMultiple":"properties","type":"property","connectors":["vault"]},{"name":"Agents","apiKey":"agent","apiKeyMultiple":"agents","type":"agent","connectors":["vault"],"permalink":true},{"name":"Inspections","apiKey":"inspection","apiKeyMultiple":"inspections","type":"inspection","connectors":["vault"],"timezone":"Australia/Brisbane"},{"name":"Suburbs","apiKey":"suburb","apiKeyMultiple":"suburbs","type":"city","connectors":["vault"]}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"typekit":{"id":"exl3krd"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-K3XX9KXX","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":true,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
