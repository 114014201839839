import React from 'react'
import {AtollonProvider} from "../../plugins/gatsby-plugin-atollon";
import layoutQuery from "../hooks/use-layout-query"
import '../assets/scss/main.scss'

import Header from '../components/header'
import Footer from '../components/footer'

const Layout = (props) => {

    const globalData = layoutQuery()
    const offices = globalData?.offices
    console.log(offices)

    return (
        <AtollonProvider {...props} globalData={globalData}>
            <Header />
            <main>
                <div className='page'>
                    { props.children }
                </div>
                <Footer />
            </main>
        </AtollonProvider>
    )
}

export default Layout
