import React from 'react'

export const Logo = ({ color = '#2D2A27' }) => (
  <svg width='112' height='38' viewBox='0 0 112 38' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M4.37525 5.12208V4.84521H18.6301V1.93811H3.03445V8.0984L6.35117 12.5975C2.18762 14.2587 0 17.5119 0 21.1804C0 25.6795 3.66956 30.0401 9.73846 30.0401C12.8435 30.0401 15.4545 29.0711 17.501 27.4099L19.1241 29.5556H22.8642L4.37525 5.12208ZM9.73846 27.2022C6.13946 27.2022 3.17558 24.5028 3.17558 21.0419C3.17558 18.7578 4.58696 16.1275 8.18595 15.0201L15.7368 25.0565C14.1843 26.3716 12.2084 27.2022 9.73846 27.2022Z'
      fill={ color }/>
    <path
      d='M22.2285 15.7121C22.2285 7.61378 28.2974 1.45349 36.4834 1.45349C42.764 1.45349 47.6332 5.1912 49.468 10.3825H45.869C44.387 6.78318 40.788 4.42981 36.4834 4.42981C30.2733 4.42981 25.6158 9.27499 25.6158 15.7121C25.6158 22.2185 30.3439 26.9945 36.5539 26.9945C40.8586 27.0637 45.1633 24.918 46.5747 19.3806H37.9653V16.612H50.3854C49.1151 26.51 42.8345 30.04 36.4834 30.04C28.368 30.04 22.2285 23.9489 22.2285 15.7121Z'
      fill={ color }/>
    <path d='M53.9844 9.06741H57.16V12.459H57.3717C58.0068 10.7286 60.0533 8.51367 63.0877 8.51367H64.4285V11.6284H62.5232C58.7125 11.6284 57.16 14.674 57.16 18.5501V29.4863H53.9844V9.06741Z'
          fill={ color }/>
    <path
      d='M65.2754 19.3115C65.2754 13.0128 69.2978 8.51367 75.2256 8.51367C79.5302 8.51367 81.7179 11.2823 82.353 12.3206H82.5647V8.99819H85.7403V25.7486C85.7403 26.3716 86.1637 26.7177 86.7988 26.7177H87.7868V29.4863H85.7403C84.0466 29.4863 82.7058 28.2404 82.7058 26.4408V26.3024H82.4941C82.0001 27.133 80.0242 30.0401 75.5078 30.0401C69.4389 30.0401 65.2754 25.6102 65.2754 19.3115ZM82.6353 19.3807C82.6353 14.3279 79.4597 11.4208 75.5078 11.4208C71.4149 11.4208 68.451 14.7432 68.451 19.3807C68.451 24.1567 71.556 27.2714 75.5784 27.2714C79.9537 27.2022 82.6353 23.6721 82.6353 19.3807Z'
      fill={ color }/>
    <path d='M111.427 9.06738L100.348 38H96.7489L99.995 29.4863H96.8194L88.9863 9.06738H92.5853L100.066 28.6557H100.348L107.828 9.06738H111.427Z' fill={ color }/>
  </svg>
)

export const Bed = ({ color = '#2D2A27' }) => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g id='np_furniture_1164886_000000 1'>
      <g id='Group'>
        <path id='Vector'
              d='M14 7.64753H13.2938C13.1175 7.64753 12.9915 7.7736 12.9915 7.9499V8.78217H3.20816V5.30237C3.20816 5.12607 3.08209 5 2.90579 5H1.99768C1.82138 5 1.69531 5.12607 1.69531 5.30237V10.3195H2.62803V10.7735C2.62803 10.8996 2.7285 11.0001 2.85457 11.0001C2.98064 11.0001 3.0811 10.8996 3.0811 10.7735V10.3195H12.9156V10.7735C12.9156 10.8996 13.0161 11.0001 13.1421 11.0001C13.2682 11.0001 13.3687 10.8996 13.3687 10.7735V10.3195H14.3024V7.97436C14.3024 7.79805 14.1763 7.64753 14 7.64753Z'
              fill={ color }/>
        <path id='Vector_2'
              d='M3.88713 8.50426H12.3081C12.4598 8.50426 12.5859 8.37819 12.5859 8.2265V7.72222C12.5859 7.57054 12.4598 7.44446 12.3081 7.44446L3.88713 7.44544C3.73546 7.44544 3.60938 7.57151 3.60938 7.7232V8.22748C3.61036 8.37818 3.73643 8.50426 3.88713 8.50426Z'
              fill={ color }/>
        <path id='Vector_3'
              d='M4.41542 7.36975C4.49126 7.44559 4.64195 7.44559 4.7434 7.36975C4.81924 7.29391 4.81924 7.14321 4.7434 7.04176L4.03721 6.33557C3.96137 6.25973 3.81067 6.25973 3.70922 6.33557C3.63338 6.4114 3.63338 6.5621 3.70922 6.66355L4.41542 7.36975Z'
              fill={ color }/>
      </g>
    </g>
  </svg>
)

export const Bath = ({ color = '#2D2A27' }) => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path fill-rule='evenodd' clip-rule='evenodd'
          d='M10.1877 12.6402V12.6917C10.1877 12.8458 10.2903 13 10.4444 13C10.547 13 10.7012 12.8458 10.7012 12.6917V12.3834C11.7286 12.1778 12.8072 11.921 12.7046 10.0203H3.35547C3.40698 11.7669 4.38292 12.3319 5.35885 12.3834V12.6917C5.35885 12.8458 5.46148 13 5.61561 13C5.71823 13 5.87237 12.8458 5.87237 12.6917V12.6402H10.1877Z'
          fill={ color }/>
    <path fill-rule='evenodd' clip-rule='evenodd'
          d='M3.2028 9.09595H12.7978C12.968 9.09595 13.1061 9.29281 13.1061 9.45253C13.1061 9.6494 12.9679 9.80912 12.7978 9.80912H3.2028C3.03269 9.80912 2.89453 9.61226 2.89453 9.45253C2.89453 9.25567 3.0327 9.09595 3.2028 9.09595Z'
          fill={ color }/>
    <path fill-rule='evenodd' clip-rule='evenodd'
          d='M3.66797 4.52417V9.30151H4.07886V4.52417C4.07886 4.06177 4.18148 3.54825 4.64388 3.39409C4.79801 3.34257 5.00366 3.34257 5.2089 3.39409C5.46566 3.4456 5.6713 3.54822 5.92807 3.80498C5.77394 4.01062 5.77394 4.26738 5.97958 4.47262L6.03109 4.42111L6.80177 3.65043L6.85328 3.59892C6.59653 3.44479 6.39088 3.44479 6.18564 3.54741C5.87737 3.29065 5.5691 3.08501 5.26083 3.0339C5.00407 2.98239 4.74731 2.98239 4.54166 3.08541C3.87401 3.29105 3.66836 3.9072 3.66836 4.5237L3.66797 4.52417Z'
          fill={ color }/>
    <path fill-rule='evenodd' clip-rule='evenodd' d='M6.84947 3.70202L6.07879 4.52381C5.92466 4.67795 6.18142 4.9347 6.33555 4.78057L7.10623 3.95878C7.31187 3.80465 7.05471 3.54789 6.84947 3.70202Z'
          fill={ color }/>
  </svg>
)

export const Car = ({ color = '#2D2A27' }) => (
  <svg width='14' height='6' viewBox='0 0 14 6' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path id='Vector' fill-rule='evenodd' clip-rule='evenodd'
          d='M10.5212 2.05982C11.5089 2.10597 14 2.56454 14 3.80294V4.39938C14 4.67452 13.7851 4.9041 13.5275 4.9041H11.8096C12.1531 4.03253 11.552 2.97749 10.6502 2.97749C9.70519 2.97749 9.10415 4.0325 9.49081 4.9041H3.64995C4.0366 4.03253 3.43502 2.97749 2.53319 2.97749C1.58816 2.97749 0.987118 4.0325 1.37378 4.9041H0.686891L0 3.71122L0.0432041 3.06922C0.601585 3.11537 0.68745 2.10592 0.0864081 2.10592V2.05978L1.97591 0.5H6.87174L10.5222 2.05978L10.5212 2.05982ZM2.53319 3.20655C3.09157 3.20655 3.56409 3.71127 3.56409 4.35327C3.56409 4.99528 3.09157 5.5 2.53319 5.5C1.93215 5.5 1.45963 4.99528 1.45963 4.35327C1.45963 3.71127 1.93215 3.20655 2.53319 3.20655ZM10.6502 3.20655C11.2086 3.20655 11.7238 3.71127 11.7238 4.35327C11.7238 4.99528 11.2086 5.5 10.6502 5.5C10.0492 5.5 9.57669 4.99528 9.57669 4.35327C9.57669 3.71127 10.0492 3.20655 10.6502 3.20655ZM3.34928 0.82142L3.04849 1.96815L5.10978 2.05986V0.821432H3.3488L3.34928 0.82142ZM2.8341 1.96815L3.13489 0.82142H2.06133L1.03044 1.92258L2.83412 1.96873L2.8341 1.96815ZM5.32521 0.82142V2.05985L10.0067 2.19771L6.78546 0.821416L5.32521 0.82142ZM12.6689 2.74804C13.1414 2.97762 13.4848 3.25276 13.6566 3.52789C13.2273 3.57404 11.9814 3.25276 12.6689 2.74804Z'
          fill={ color }/>
  </svg>
)

export const RightArrow = ({ color = '#2D2A27' }) => (
  <svg width='13' height='22' viewBox='0 0 13 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path fillRule='evenodd' clipRule='evenodd' d='M1.99926 0.606891L12.4141 11.0207L1.99926 21.4346L0.585517 20.0208L9.58552 11.0208L0.585519 2.02083L1.99926 0.606891Z' fill={ color }/>
  </svg>
)

export const Sold = ({ color = '#FFFFFF' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 1080 559.02">
    <path fill={color} d="M217.1,209.77v-1.65h98.38v-20.51h-107.57v43.56l23.1,31.21c-28.99,11.94-43.97,34.46-43.97,60.5,0,31.83,25.21,62.31,67.32,62.31,21.6,0,39.68-6.72,53.63-18.55l11.06,14.93h26.04l-127.98-171.8ZM254.36,365.24c-24.65,0-45.47-19.12-45.47-43.51,0-16.07,9.97-34.77,34.62-42.37l52.13,70.58c-10.44,9.56-24.18,15.29-41.28,15.29Z"/>
    <g>
      <path fill={color} d="M353.93,323.96h22.44c0,22.44,18.28,40.44,47.64,40.44,25.48,0,43.76-12.46,43.76-32.69,0-60.66-107.2-18.28-107.2-95.84,0-27.7,22.99-51.8,62.05-51.8,29.36,0,63.71,16.07,63.71,56.23h-22.99c0-22.16-14.68-35.73-41-35.73s-39.33,15.23-39.33,31.3c0,52.91,107.47,16.07,107.47,94.45,0,30.47-24.93,54.84-67.31,54.84-38.5,0-69.25-25.21-69.25-61.22Z"/>
      <path fill={color} d="M504.34,309.56c0-43.49,32.41-75.62,75.34-75.62s75.07,32.41,75.07,75.62-32.13,75.62-75.34,75.62-75.06-31.85-75.06-75.62ZM632.31,309.56c0-31.85-21.88-55.68-52.91-55.68s-52.63,24.1-52.63,55.68,21.88,55.68,52.63,55.68,52.91-23.54,52.91-55.68Z"/>
      <path fill={color} d="M664.45,362.74h29.08v-170.07h-29.08v-18.84h50.97v188.91h29.08v18.84h-80.05v-18.84Z"/>
      <path fill={color} d="M751.43,309.84c0-44.32,27.98-75.9,68.69-75.9,30.19,0,44.87,19.39,49.3,26.87h1.66v-86.98h21.88v207.74h-21.05v-22.71h-1.66c-3.6,5.26-17.17,26.31-48.47,26.31-40.99,0-70.36-31.3-70.36-75.34ZM871.92,310.11c0-35.73-22.16-56.23-49.3-56.23s-48.75,23.27-48.75,56.23,21.33,55.4,49.3,55.4c29.92,0,48.75-25.48,48.75-55.4Z"/>
    </g>
  </svg>
)

export const Search = ({ color = '#A9A9AA' }) => (
  <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M6.72731 1.64575C8.40134 2.61225 8.9749 4.75281 8.0084 6.42684C7.0419 8.10086 4.90134 8.67443 3.22731 7.70793C1.55329 6.74143 0.979727 4.60086 1.94622 2.92684C2.91272 1.25281 5.05329 0.679251 6.72731 1.64575ZM9.12684 6.41917C9.99492 4.35903 9.21365 1.92654 7.22731 0.779723C5.075 -0.462917 2.32284 0.274521 1.0802 2.42684C-0.162441 4.57915 0.574997 7.33131 2.72731 8.57395C4.7528 9.74337 7.30952 9.15919 8.63946 7.29247L13.0241 9.82395L13.5241 8.95792L9.12684 6.41917Z" fill={color}/>
  </svg>
)