import React from 'react'
import { Logo } from './icons'
import {graphql, useStaticQuery} from "gatsby";
import { hierarchicalMenu, Link } from "../../plugins/gatsby-plugin-atollon"

const Header = () => {

  const [offCanvas, setOffCanvas] = React.useState(false)

  const languageKey = 'au'

  const staticData = useStaticQuery(graphql`
    query DesktopHeaderData {
      au: allDatoCmsHeaderMenuItem {
        nodes {
          id
          treeParent {
            id
          }
          title
          position
          link {
            ...LinkWithoutTitleProps
          }
          hideForLocale
        }
      }
    }
  `);
  const menuForLang = staticData[languageKey].nodes
  const menu = hierarchicalMenu(menuForLang)

  let props = {
    onClick: () => setOffCanvas(false),
    activeClassName: 'active',
  }

  return (
    <>
      <header className='header'>
        <button onClick={() => setOffCanvas(!offCanvas)} className={offCanvas ? 'header__hamburger active' : 'header__hamburger' }>
          <span className='lines'></span>
        </button>
        <nav className='header__nav'>
          { menu.childMenu &&
            <ul>
              { menu.childMenu.map((item, index) => (
                <li key={index}><Link {...item.link}>{item.title}</Link></li>
              )) }
            </ul>
          }
        </nav>
        <div className='header__logo'>
          <Link to='/' {...props}>
            <Logo color='#fff' />
          </Link>
        </div>
      </header>
      <div className={`off-canvas ${offCanvas ? 'active' : ''}`}>
        <nav className='off-canvas__nav'>
          { menu.childMenu &&
            <ul>
              { menu.childMenu.map((item, index) => (
                <li key={index}><Link {...item.link} {...props}>{item.title}</Link></li>
              )) }
            </ul>
          }
        </nav>
      </div>
    </>
  )
}

export default Header
