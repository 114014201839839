import { useStaticQuery, graphql } from "gatsby"

const useLayoutQuery = () => {
  return useStaticQuery(graphql`
    query {
      ...GlobalData
    }
  `)
}

export default useLayoutQuery